import axios from "axios";

const commerceGPTLandingPageAPI = axios.create({
  baseURL: process.env.COMMERCE_GPT_API,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem(
    'accessToken',
  )}`
  }
});

export default commerceGPTLandingPageAPI;